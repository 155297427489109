import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';
import jwt_decode from 'jwt-decode';

import { getToken, clearToken } from '@iso/lib/helpers/utility';
import actions from './actions';

const history = createBrowserHistory();
// const fakeApiCall = true; // auth0 or express JWT

const getData = async (username, password) => {
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ username, password })
	};

	const responseData = await fetch('https://dashboard.brotkost.at/api/login', requestOptions);
	const response = await responseData.json();

	let token = null;
	let role = null;

	if (response.token) {
		token = response.token;
		role = jwt_decode(response.token).role;
	} else {
		console.log(response.error);
	}
	return { token, role };
};

export function* loginRequest () {
	yield takeEvery('LOGIN_REQUEST', function* ({ payload }) {
		const { username, password } = payload;
		const { token, role } = yield call(getData, username, password);

		if (token) {
			yield put({
				type: actions.LOGIN_SUCCESS,
				token: token,
				role: role,
				profile: 'Profile'
			});
		} else {
			yield put({ type: actions.LOGIN_ERROR });
		}
	});
}

export function* loginSuccess () {
	yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
		yield localStorage.setItem('id_token', payload.token);
	});
}

export function* loginError () {
	yield takeEvery(actions.LOGIN_ERROR, function* () {});
}

export function* logout () {
	yield takeEvery(actions.LOGOUT, function* () {
		yield clearToken();
		history.push('/');
	});
}
export function* checkAuthorization () {
	yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
		const token = getToken().get('idToken');
		const role = getToken().get('role');
		if (token) {
			yield put({
				type: actions.LOGIN_SUCCESS,
				token,
				role,
				profile: 'Profile'
			});
		}
	});
}
export default function* rootSaga () {
	yield all([ fork(checkAuthorization), fork(loginRequest), fork(loginSuccess), fork(loginError), fork(logout) ]);
}
