export const CALENDAR_VIEW = 'CALENDAR_VIEW';
export const CALENDAR_EVENTS = 'CALENDAR_EVENTS';

export const changeView = view => ({
	type: CALENDAR_VIEW,
	view
});

export const changeEvents = events => ({
	type: CALENDAR_EVENTS,
	events
});
