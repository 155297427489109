import { CALENDAR_VIEW, CALENDAR_EVENTS } from './actions';
import events from './DemoEvents';

const initState = {
	events,
	view: 'month'
};

export default function calendarReducer (state = initState, action) {
	switch (action.type) {
		case CALENDAR_VIEW:
			return {
				...state,
				view: action.view
			};
		case CALENDAR_EVENTS:
			return {
				...state,
				events: action.events
			};
		default:
			return state;
	}
}
